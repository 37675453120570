import { useGetCurrentUser } from 'store/users/selectors';

const useLoggedInUserInfo = () => {
  const currentUser = useGetCurrentUser();
  const isLoggedInUser = Boolean(currentUser && currentUser.isConfirmed);

  return {
    isLoggedInUser,
    loggedUserRoleName: isLoggedInUser && currentUser ? currentUser.roleName : null,
  };
};

export default useLoggedInUserInfo;
