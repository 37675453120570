import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import styled from 'styled-components';

import { APP_ELEMENT_ID } from 'constants/layout';
import { misc } from 'helpers/styles/constants';
import AppFooter from 'components/AppFooter/AppFooter';
import AppHeader from 'components/AppHeader/AppHeader';

import App from './App';

const Landing = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${misc.siteMaxWidth}px;
  min-height: 100vh;
  margin: 0 auto;
`;

const LandingContent = styled.div`
  flex-grow: 1;
`;

export interface Props {
  children: React.ReactNode;
  hideNav?: boolean;
}

export default function LandingApp({ children, hideNav }: Props) {
  return (
    <BrowserRouter>
      <App>
        <Landing id={APP_ELEMENT_ID} data-test="app-landing">
          <AppHeader hideNav={hideNav} />
          <LandingContent>{children}</LandingContent>
          <AppFooter />
        </Landing>
      </App>
    </BrowserRouter>
  );
}
