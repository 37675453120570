import React from 'react';
import { FormattedMessage } from 'react-intl';
import { position, rem, size } from 'polished';
import styled from 'styled-components';

import { breakpoints } from 'helpers/styles/constants';
import AppCommonNavItem, {
  Props as ContainerProps,
  RenderProps,
} from 'components/AppCommonNavItem/AppCommonNavItem';
import HiddenText from 'components/HiddenText/HiddenText';

const Icon = styled.i.attrs({
  'data-test': 'nav-item-icon',
})`
  ${size(rem(16), rem(27))};
  position: relative;
  top: ${rem(3)};
  display: inline-block;
  padding-right: ${rem(10)};
  margin-top: ${rem(-1)};
`;

const ItemInner = styled.span<{ isActive?: boolean }>`
  position: relative;
  display: block;
  padding: ${rem(15)} 0;

  @media ${breakpoints.mdUp} {
    padding: 0;
  }

  &::before {
    ${position('absolute', rem(10), null, rem(10), rem(-10))};
    content: '';
    width: 2px;
    background-color: ${({ theme }) => theme.colors.accent};
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: opacity 0.15s ease;

    @media ${breakpoints.mdUp} {
      right: 0;
      left: 0;
      top: auto;
      bottom: ${rem(-5)};
      width: auto;
      border-bottom: ${rem(2)} solid ${({ theme }) => theme.colors.accent};
    }
  }
`;

const Item = styled.li`
  font-weight: ${({ theme }) => theme.fontWeights.mainMedium};

  @media ${breakpoints.mdUp} {
    &:not(:first-child) {
      margin-left: ${rem(10)};
    }

    &:not(:last-child) {
      margin-right: ${rem(10)};
    }

    &.is-pull-left {
      margin-right: auto;
    }

    &.is-border {
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: ${rem(-5)};
        bottom: ${rem(-5)};
        width: 1px;
        background-color: ${({ theme }) => theme.colors.accent};
      }

      &--left {
        padding-left: ${rem(35)};
        margin-left: 0;

        &::before {
          position: absolute;
          left: ${rem(13)};
        }
      }

      &--right {
        padding-right: ${rem(35)};
        margin-right: 0;

        &::before {
          position: absolute;
          right: ${rem(13)};
        }
      }
    }
  }

  @media ${breakpoints.lgUp} {
    &:not(:first-child) {
      margin-left: ${rem(15)};
    }

    &:not(:last-child) {
      margin-right: ${rem(15)};
    }

    &.is-border {
      &--left {
        padding-left: ${rem(50)};

        &::before {
          left: ${rem(17)};
        }
      }

      &--right {
        padding-right: ${rem(50)};

        &::before {
          right: ${rem(17)};
        }
      }
    }
  }

  &:hover {
    color: ${({ theme }) => theme.colors.accent};
  }

  &.is-italic {
    font-style: italic;
    opacity: 0.7;
  }

  &.is-accent {
    color: ${({ theme }) => theme.colors.accent};

    &:hover {
      color: ${({ theme }) => theme.colors.accentHover};
    }
  }

  &.is-image ${/* sc-sel */ ItemInner}::before {
    content: none;
  }

  &:not(.is-image) ${ItemInner} {
    outline: none;
  }
`;

export type AllProps = Props & RenderProps;

interface Props {
  icon?: React.ComponentType;
  logo?: React.ReactNode;
  isActive?: boolean;
}

const NavItem = ({ className, icon, text, logo, isActive, tagProps, TagToRender }: AllProps) => (
  <Item className={className}>
    <ItemInner as={TagToRender} isActive={isActive} {...tagProps} data-test="nav-item-tag">
      {icon && <Icon as={icon} />}

      {logo ? (
        <>
          {logo}
          <HiddenText text={text} />
        </>
      ) : (
        <span data-test="nav-item-name">
          <FormattedMessage id={text} />
        </span>
      )}
    </ItemInner>
  </Item>
);

export { NavItem as NavItemUnwrapped };

const render = (renderProps: RenderProps) => <NavItem {...renderProps} />;
export default (props: ContainerProps & Props) => <AppCommonNavItem render={render} {...props} />;
