import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem, size } from 'polished';
import styled from 'styled-components';

import AppCommonNavItem, {
  Props as ContainerProps,
  RenderProps,
} from 'components/AppCommonNavItem/AppCommonNavItem';

const Link = styled.i<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0;
  outline: none;
  color: ${({ theme }) => theme.colors.main};
  transition: 0.3s ease color;

  ${({ isActive, theme }) => isActive && `color: ${theme.colors.accent};`};

  &:hover {
    color: ${({ theme }) => theme.colors.catshillWhite};
  }
`;

const NavIcon = styled.i.attrs({
  'data-test': 'nav-item-icon',
})`
  position: relative;
  ${size(rem(16), rem(24))};
  display: inline-block;
  padding-right: ${rem(10)};
`;

interface ItemProps {
  hasMargin?: boolean;
}

const Item = styled.li<ItemProps>`
  display: flex;
  align-items: center;
  min-height: ${rem(30)};

  ${({ hasMargin }) => hasMargin && `margin-bottom: ${rem(25)};`};

  &.is-accent {
    ${Link} {
      color: ${({ theme }) => theme.colors.accent};

      &:hover {
        color: ${({ theme }) => theme.colors.accentHover};
      }
    }
  }

  &.is-login-icon ${NavIcon} {
    ${size(rem(28), rem(24))};
  }
`;

interface Props {
  icon?: React.ComponentType;
  isTextTranslated?: boolean;
  hasMargin?: boolean;
}

export type AllProps = Props & RenderProps;

const NavItem = ({
  className,
  icon,
  text,
  isTextTranslated,
  isActive,
  tagProps,
  TagToRender,
  hasMargin,
}: AllProps) => (
  <Item className={className} hasMargin={hasMargin}>
    <Link as={TagToRender} isActive={isActive} {...tagProps} data-test="nav-item-tag">
      {icon && <NavIcon as={icon} />}
      {isTextTranslated ? text : <FormattedMessage id={text} />}
    </Link>
  </Item>
);

export { NavItem as NavItemUnwrapped };

const render = (renderProps: RenderProps) => <NavItem {...renderProps} />;
export default (props: ContainerProps & Props) => <AppCommonNavItem render={render} {...props} />;
