import React from 'react';
import { useIntl } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import { breakpoints, misc } from 'helpers/styles/constants';
import { flexCenter } from 'helpers/styles/mixins';

const ImageWrapper = styled.div`
  ${flexCenter()};
  max-width: 80px;

  @media ${breakpoints.mdUp} {
    max-width: 120px;
    height: ${rem(misc.headerHeight)};
    margin-bottom: auto;
  }

  @media ${breakpoints.mdDown} {
    min-height: ${rem(misc.headerHeight)};
  }

  img {
    object-fit: contain;

    @media ${breakpoints.mdUp} {
      height: 100%;
    }
  }
`;

interface Props {
  src: string;
}

const SecondaryLogo = ({ src }: Props) => {
  const intl = useIntl();

  return (
    <ImageWrapper>
      <img
        data-test="img-icon"
        src={src}
        alt={intl.formatMessage({
          id: 'practiceSettings.general.practiceOverview.secondaryLogo.title',
        })}
      />
    </ImageWrapper>
  );
};

export default SecondaryLogo;
