import React from 'react';

import SvgIcon from './SvgIcon';

const IconLogin = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 16 15" {...props}>
    <g fill="currentColor">
      <path
        d="M6 4.5a.5.5 0 0 1-1 0v-3A1.5 1.5 0 0 1 6.5 0h8A1.5 1.5 0 0 1 16 1.5v12a1.5 1.5 0 0
        1-1.5 1.5h-8A1.5 1.5 0 0 1 5 13.5v-3a.5.5 0 1 1 1 0v3a.5.5 0 0 0 .5.5h8a.5.5 0 0 0
        .5-.5v-12a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v3z"
      />
      <path d="M.5 8a.5.5 0 0 1 0-1h11a.5.5 0 1 1 0 1H.5z" />
      <path
        d="M8.15 4.85a.5.5 0 1 1 .7-.7l3 3a.5.5 0 0 1 0 .7l-3 3a.5.5 0 0
        1-.7-.7l2.64-2.65-2.64-2.65z"
      />
    </g>
  </SvgIcon>
);

export default IconLogin;
