import React from 'react';

import SvgIcon from './SvgIcon';

const IconSignOutThin = (props: React.SVGAttributes<SVGSVGElement>) => (
  <SvgIcon viewBox="0 0 16 16" {...props}>
    <path
      d="M7 5V2c0-.55.4-1 .89-1h6.22c.5 0 .89.45.89 1v12c0 .55-.4 1-.89
        1H7.89c-.5 0-.89-.45-.89-1v-3m4-3H1m3-3L1 8l3 3"
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default IconSignOutThin;
