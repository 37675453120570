import { logOut } from 'helpers/users';
import { getPathName } from 'helpers/utils';

export interface RenderProps {
  onLogoutUser: () => void;
}

const AppFooterContainer = ({ render }: ContainerProps<RenderProps>) =>
  render({
    onLogoutUser: () => logOut(getPathName()),
  });

export default AppFooterContainer;
