import { useCallback } from 'react';
import { get } from 'lodash';

import type { LandingPage } from 'constants/landingPages';
import { useGetDisabledPages } from 'store/settings/selectors';

export const useIsPageVisible = () => {
  const disabledPages = useGetDisabledPages();

  return useCallback<(pageName: LandingPage) => boolean>(
    pageName => !get(disabledPages, pageName),
    [disabledPages]
  );
};
