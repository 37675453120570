import { getIsOnPage, getPathName, isBlank } from 'helpers/utils';

export interface RenderProps extends Pick<Props, 'className' | 'text'> {
  isActive: boolean;
  TagToRender: 'a' | 'button';
  tagProps:
    | React.ButtonHTMLAttributes<HTMLButtonElement>
    | React.AnchorHTMLAttributes<HTMLAnchorElement>;
}

export interface Props {
  className?: string;
  text: string;
  href?: string;
  onClick?: () => void;
  isNewWindow?: boolean;
}

const AppCommonNavItem = ({
  href,
  onClick,
  isNewWindow,
  render,
  ...rest
}: Props & ContainerProps<RenderProps>) => {
  const pathName = getPathName();

  const TagToRender = onClick ? 'button' : 'a';
  let tagProps = {};

  if (onClick) {
    tagProps = {
      ...tagProps,
      type: 'button',
      onClick,
    };
  } else if (!isBlank(href)) {
    tagProps = {
      href,
      target: isNewWindow ? '_blank' : null,
      rel: isNewWindow ? 'noopener noreferrer' : null,
    };
  } else {
    return null;
  }

  return render({
    TagToRender,
    tagProps,
    isActive: Boolean(href && pathName && getIsOnPage(pathName, href)),
    ...rest,
  });
};

export default AppCommonNavItem;
