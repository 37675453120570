import React from 'react';
import { rem, size } from 'polished';
import styled, { css } from 'styled-components';

import { LandingPage } from 'constants/landingPages';
import { isEnabled, ServerSideFeature } from 'helpers/features';
import { breakpoints, misc } from 'helpers/styles/constants';
import { container } from 'helpers/styles/mixins';
import useLoggedInUserInfo from 'hooks/useLoggedInUserInfo';
import routes, { getDashboardRoute } from 'routes';
import { useGetPracticeSettings } from 'store/settings/selectors';
import HamburgerMenuButton from 'components/HamburgerMenuButton/HamburgerMenuButton';
import HiddenText from 'components/HiddenText/HiddenText';
import IconLogin from 'components/Icons/IconLogin';
import IconLogout from 'components/Icons/IconSignOutThin';
import LogoIcon from 'components/LogoIcon/LogoIcon';
import { useIsPageVisible } from 'components/PracticeSettings/useIsPageVisible';

import AppHeaderContainer, { Props as ContainerProps, RenderProps } from './AppHeaderContainer';
import NavItem from './components/NavItem/NavItem';
import SecondaryLogo from './components/SecondaryLogo/SecondaryLogo';

const MobileSeparator = styled.li`
  ${size('1px', '100%')};
  margin: ${rem(15)} 0;
  background-color: ${({ theme }) => theme.colors.accent};

  @media ${breakpoints.mdUp} {
    display: none;
  }
`;

const Logo = styled.a`
  display: inline-block;
  margin-right: ${rem(10)};

  img {
    height: 100%;
    width: 100%;
    max-width: ${rem(140)};
    backface-visibility: hidden;
    transform: translateZ(0);

    @media ${breakpoints.mdUp} {
      max-width: ${rem(220)};
    }
  }
`;

const NavTrigger = styled.div`
  display: flex;
  margin-left: ${rem(30)};

  @media ${breakpoints.mdUp} {
    display: none;
  }
`;

const HeaderContent = styled.div`
  ${container()};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: ${rem(misc.gutter)};

  @media ${breakpoints.lgUp} {
    padding: ${rem(misc.gutterLg)};
  }
`;

const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  height: 100%;
`;

const HeaderNav = styled.ul<{ isActive?: boolean }>`
  @media ${breakpoints.smDown} {
    position: fixed;
    top: ${rem(misc.headerHeight)};
    left: 100vw;
    overflow-y: auto;
    width: ${rem(400)};
    padding: ${rem(15)};
    max-width: 100%;
    max-height: calc(100vh - ${rem(misc.headerHeightWithMargin)});
    border-top: 1px solid ${({ theme }) => theme.colors.greyLighten};
    box-shadow: -1px 6px 6px 1px rgba(0, 0, 0, 0.1);
    background-color: ${({ theme }) => theme.colors.white};
    transition: transform 0.2s ease;
    -webkit-overflow-scrolling: touch;

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translateX(-100%);
      `};
  }

  @media ${breakpoints.mdUp} {
    display: flex;
    align-items: center;
    overflow: hidden;
    min-height: ${rem(misc.headerHeight)};
    margin-left: auto;
    margin-bottom: auto;
  }
`;

const Header = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.headerBg};
`;

const AppHeader = ({ isNavVisible, onLogoutUser, onToggleNav, hideNav }: RenderProps) => {
  const { isLoggedInUser, loggedUserRoleName } = useLoggedInUserInfo();
  const isPageVisible = useIsPageVisible();
  const { secondaryLogo, onboardingDisabled, tradingName } = useGetPracticeSettings();

  const renderNav = () => {
    const leftLinks = [
      !onboardingDisabled && isPageVisible(LandingPage.HowItWorks) && (
        <NavItem text="nav.howItWorks" key="howItWorks" href={routes.howItWorks.path()} />
      ),
      isPageVisible(LandingPage.Faq) && (
        <NavItem text="nav.faq" key="faq" href={routes.faq.path()} />
      ),
    ].filter(Boolean);

    const leftNavItemClassNames = `is-accent${
      leftLinks.length ? ' is-border is-border--left' : ''
    }`;

    const rightNavItemClassNames = `is-accent${secondaryLogo ? ' is-border is-border--right' : ''}`;

    const renderSignIn = () => {
      if (isLoggedInUser && loggedUserRoleName) {
        const dashboardRoute = getDashboardRoute(loggedUserRoleName);

        return (
          <>
            {dashboardRoute && (
              <NavItem
                className={leftNavItemClassNames}
                text="nav.dashboard"
                href={dashboardRoute}
              />
            )}

            <NavItem
              className={rightNavItemClassNames}
              text="nav.signOut"
              data-test="app-header-nav-item-signout"
              onClick={onLogoutUser}
              icon={IconLogout}
            />
          </>
        );
      }

      return (
        <>
          {isEnabled(ServerSideFeature.BecomeClient) && !onboardingDisabled && (
            <NavItem
              className={leftNavItemClassNames}
              text="nav.signUp"
              href={routes.signUp.path()}
            />
          )}

          <NavItem
            className={rightNavItemClassNames}
            text="nav.signIn"
            href={routes.root.path()}
            icon={IconLogin}
            isActive={false}
          />
        </>
      );
    };

    return (
      <HeaderNav isActive={isNavVisible}>
        {leftLinks}
        <MobileSeparator />
        {renderSignIn()}
      </HeaderNav>
    );
  };

  return (
    <Header>
      <HeaderContent>
        <NavWrapper>
          {tradingName ? (
            <Logo title={tradingName} href={routes.root.path()}>
              <LogoIcon />

              <HiddenText text={tradingName} isTranslated />
            </Logo>
          ) : null}

          {!hideNav && renderNav()}

          {secondaryLogo && <SecondaryLogo src={secondaryLogo} />}
        </NavWrapper>

        {!hideNav && (
          <NavTrigger>
            <HamburgerMenuButton onClick={onToggleNav} />
          </NavTrigger>
        )}
      </HeaderContent>
    </Header>
  );
};

export { AppHeader as AppHeaderUnwrapped };

const render = (renderProps: RenderProps) => <AppHeader {...renderProps} />;
export default (props: ContainerProps) => <AppHeaderContainer render={render} {...props} />;
