import React from 'react';
import { FormattedMessage } from 'react-intl';
import { rem } from 'polished';
import styled from 'styled-components';

import { LandingPage } from 'constants/landingPages';
import { isEnabled, ServerSideFeature } from 'helpers/features';
import { breakpoints } from 'helpers/styles/constants';
import { container } from 'helpers/styles/mixins';
import { getActualYear, isNotBlank } from 'helpers/utils';
import useLoggedInUserInfo from 'hooks/useLoggedInUserInfo';
import routes, { getDashboardRoute } from 'routes';
import { useGetPracticeSettings } from 'store/settings/selectors';
import BaseFontSizeChanger from 'components/BaseFontSizeChanger/BaseFontSizeChanger';
import { DwsLogo } from 'components/DwsLogo/DwsLogo';
import RichTextEditor from 'components/GenericTextarea/RichTextEditor';
import { fromHTML } from 'components/GenericTextarea/utils';
import IconLogin from 'components/Icons/IconLogin';
import IconLogout from 'components/Icons/IconSignOutThin';
import { Column, Grid, Row } from 'components/Layout';
import Margin from 'components/Margin/Margin';
import { useIsPageVisible } from 'components/PracticeSettings/useIsPageVisible';

import AppFooterContainer, { RenderProps } from './AppFooterContainer';
import NavItem from './components/NavItem/NavItem';

const textOpacity = 0.5;

const Description = styled.div`
  opacity: ${textOpacity};

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  a {
    text-decoration: underline;
  }

  ol,
  ul {
    margin: ${rem(14)} 0;
    padding-left: ${rem(40)};
  }

  ul {
    list-style-type: disc;
  }
`;

const Footer = styled.div`
  position: relative;
  margin-top: ${rem(60)};
  font-size: ${({ theme }) => theme.fontSizes.small};
  line-height: 2;
  color: ${({ theme }) => theme.colors.main};
  border-top: ${rem(10)} solid ${({ theme }) => theme.colors.accent};

  @media ${breakpoints.mdUp} {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const FooterContent = styled.div`
  ${container()};
  display: flex;
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};

  @media ${breakpoints.smUp} {
    padding-top: ${rem(60)};
    padding-bottom: ${rem(60)};
  }
`;

const Copy = styled.strong`
  margin-right: ${rem(16)};
  opacity: ${textOpacity};
`;

function AppFooter({ onLogoutUser }: RenderProps) {
  const { isLoggedInUser, loggedUserRoleName } = useLoggedInUserInfo();
  const isPageVisible = useIsPageVisible();
  const {
    onboardingDisabled,
    authorizedRepresentativeName,
    footerText,
    privacyPolicyUrl,
    mainWebsiteUrl,
    mainWebsiteUrlTitle,
  } = useGetPracticeSettings();

  const renderTopNavItem = () => {
    if (isLoggedInUser && loggedUserRoleName) {
      const dashboardRoute = getDashboardRoute(loggedUserRoleName);

      return (
        <NavItem
          data-test="app-footer-dashboard"
          className="is-accent"
          text="nav.dashboard"
          href={dashboardRoute}
        />
      );
    }

    if (isEnabled(ServerSideFeature.BecomeClient)) {
      return onboardingDisabled ? (
        <Margin top={52.25} />
      ) : (
        <NavItem className="is-accent" text="nav.signUp" href={routes.signUp.path()} />
      );
    }

    return null;
  };

  return (
    <Footer>
      <FooterContent>
        <Grid>
          <Row>
            <Column mdUp={6}>
              <ul>
                {renderTopNavItem()}

                {isLoggedInUser ? (
                  <NavItem
                    data-test="app-footer-nav-item-logout"
                    className="is-accent"
                    text="nav.signOut"
                    icon={IconLogout}
                    onClick={onLogoutUser}
                    hasMargin
                  />
                ) : (
                  <NavItem
                    data-test="app-footer-nav-item-login"
                    className="is-accent is-login-icon"
                    text="nav.signIn"
                    icon={IconLogin}
                    href={routes.users.signIn.path()}
                    hasMargin
                  />
                )}

                {isPageVisible(LandingPage.HowItWorks) && !onboardingDisabled && (
                  <NavItem text="nav.howItWorks" href={routes.howItWorks.path()} />
                )}

                {isPageVisible(LandingPage.Faq) && (
                  <NavItem text="nav.faq" href={routes.faq.path()} />
                )}

                {isPageVisible(LandingPage.HowWeCommunicate) && (
                  <NavItem text="nav.howWeCommunicate" href={routes.howWeCommunicate.path()} />
                )}

                {isPageVisible(LandingPage.SecurityStatement) && (
                  <NavItem text="nav.securityStatement" href={routes.securityStatement.path()} />
                )}

                <NavItem text="nav.privacyPolicy" href={privacyPolicyUrl} isNewWindow />

                <NavItem text="cookies.pageTitle" href={routes.cookies.path()} />

                {isNotBlank(mainWebsiteUrlTitle) && isNotBlank(mainWebsiteUrl) && (
                  <NavItem
                    text={mainWebsiteUrlTitle}
                    href={mainWebsiteUrl}
                    isTextTranslated
                    isNewWindow
                  />
                )}
              </ul>
            </Column>

            <Column mdUp={6}>
              <Margin top={{ smDown: 30 }} />

              {footerText && (
                <Description>
                  <RichTextEditor
                    value={fromHTML(footerText)}
                    minVerticalSize={null}
                    hasNoPadding
                    readOnly
                    transparent
                  />
                </Description>
              )}
            </Column>
          </Row>

          <Row>
            <Column mdUp={6}>
              {isEnabled(ServerSideFeature.DwsLogo) && (
                <Margin top={20}>
                  <DwsLogo />
                </Margin>
              )}
            </Column>

            <Column mdUp={6}>
              <Margin top={20}>
                <Copy>
                  <FormattedMessage
                    id="shared.footer.copy"
                    values={{ year: getActualYear(), authorizedRepresentativeName }}
                  />
                </Copy>

                <BaseFontSizeChanger />
              </Margin>
            </Column>
          </Row>
        </Grid>
      </FooterContent>
    </Footer>
  );
}

export { AppFooter as AppFooterUnwrapped };

const render = (renderProps: RenderProps) => <AppFooter {...renderProps} />;
export default () => <AppFooterContainer render={render} />;
