import { PureComponent } from 'react';

import { Key } from 'constants/keys';
import { logOut } from 'helpers/users';
import { getPathName, isBrowser } from 'helpers/utils';

interface State {
  isNavVisible: boolean;
}

export interface Props {
  hideNav?: boolean;
}

export interface RenderProps extends State, Props {
  onLogoutUser: () => void;
  onToggleNav: () => void;
  isNavVisible: boolean;
}

export default class AppHeaderContainer extends PureComponent<
  Props & ContainerProps<RenderProps>,
  State
> {
  public state = {
    isNavVisible: false,
  };

  public componentDidMount() {
    if (!isBrowser()) return;
    window.addEventListener('keyup', this.handleKeyUp);
  }

  public componentWillUnmount() {
    if (!isBrowser()) return;
    window.removeEventListener('keyup', this.handleKeyUp);
  }

  private handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === Key.Escape) {
      this.setState({ isNavVisible: false });
    }
  };

  private handleLogOut = () => logOut(getPathName());

  private toggleNav = () => this.setState(({ isNavVisible }) => ({ isNavVisible: !isNavVisible }));

  public render() {
    const { render, hideNav } = this.props;
    const { isNavVisible } = this.state;

    return render({
      onLogoutUser: this.handleLogOut,
      onToggleNav: this.toggleNav,
      isNavVisible,
      hideNav,
    });
  }
}
